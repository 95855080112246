import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { CurrencySet } from '../models/contributeData';
import { getFunctionWithName } from '../models/CustomForms.model';

export class CustomValidators {
  public static required(control: UntypedFormControl): { [key: string]: boolean } {
    if (typeof control.value === 'string') return !control.value.trim() ? { required: true } : null;
    if (typeof control.value === 'number') return isNaN(control.value) || !control.value ? { required: true } : null;
    return !control.value ? { required: true } : null;
  }

  public static passwordsMatch(group: UntypedFormGroup): { [key: string]: any } {
    const newPassword = group.get('newPassword');
    const confPassword = group.get('confPassword');
    if (newPassword.value && confPassword.value && newPassword.value !== confPassword.value) {
      confPassword.setErrors({ passwordsMatch: true });
      return { passwordsMatch: true };
    }
    if (!newPassword.value || confPassword.value) {
      confPassword.setErrors(null);
    } else {
      confPassword.setErrors({ required: true });
    }
    return null;
  }

  public static newPasswordDifferent(group: UntypedFormGroup): { [key: string]: any } {
    const oldPassword: AbstractControl = group.get('oldPassword');
    const newPassword: AbstractControl = group.get('newPassword');
    if (oldPassword.value && newPassword.value && oldPassword.value === newPassword.value) {
      newPassword.setErrors({ ...newPassword.errors, newPasswordDifferent: true });
      return { newPasswordDifferent: true };
    }
    if (!oldPassword.value || newPassword.value) {
      const NewPassErrors = newPassword.errors ? { ...newPassword.errors } : null;
      if (NewPassErrors?.newPasswordDifferent) delete NewPassErrors.newPasswordDifferent;
      newPassword.setErrors(NewPassErrors);
    }
    return null;
  }

  public static password(control: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^\s]+$/;
    return regExp.test(control.value) || !control.value ? null : { password: true };
  }

  public static positiveNumber(control: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp = /[0-9]/;
    return !control.value || (regExp.test(control.value) && Number(control.value) > 0)
      ? null
      : { positiveNumber: true };
  }
  public static maxAmountAllowed(value: number): ValidatorFn {
    const error = 'maxAmountAllowed';
    const maxAmountAllowed = (control: UntypedFormControl): { [key: string]: any } => {
      return !control.value || control.value <= value ? null : { [error]: true };
    };
    return getFunctionWithName(maxAmountAllowed, error);
  }

  public static minAmountAllowed(value: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      return c.value && c.value < value ? { minAmountAllowed: true } : null;
    };
  }

  public static minLength(value: number): ValidatorFn {
    const error = 'minLength';
    const minLength = (control: UntypedFormControl): { [key: string]: any } => {
      return !control.value || control.value.trim().length >= value ? null : { [error]: true };
    };
    return getFunctionWithName(minLength, error);
  }

  public static maxLength(value: number): ValidatorFn {
    const error = 'maxLength';
    const maxLength = (control: UntypedFormControl): { [key: string]: any } => {
      return control.value && control.value.length > value ? { [error]: true } : null;
    };
    return getFunctionWithName(maxLength, error);
  }

  public static maxValue(value: number): ValidatorFn {
    const error = 'maxValue';
    const maxValue = (control: UntypedFormControl): { [key: string]: any } => {
      return value === null ? null : control.value > value ? { [error]: true } : null;
    };
    return getFunctionWithName(maxValue, error);
  }

  public static checkCurrency(currencies: CurrencySet[]): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value?.length) {
        return currencies?.some((input) => c.value?.trim() === input) ? null : { exist: true };
      }
    };
  }

  public static pin(c: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp = /[1-9]\d{3}/;
    return !c.value || regExp.test(c.value) ? null : { pin: true };
  }

  public static specialChars(c: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp = /[!#$%&'()*+,/:;<=>?@[\]^_`{|}~]/;
    return regExp.test(c.value) ? { specialChars: true } : null;
  }

  public static numbersCharacters(c: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp = /[0-9!#$%&'()*+,/:;<=>?@[\]^_`{|}~]/;
    return regExp.test(c.value) ? { numbersCharacters: true } : null;
  }

  public static lettersNumbersCharacters(c: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp = /^[a-zA-Z0-9\-,:;_@#().äöüß 'ÀÂÄÇÉÈÊËÎÏÔÖÙÛÜŸàâäçéèêëîïôöùûüÿÆŒæœ]*$/;
    return regExp.test(c.value) ? null : { lettersNumbersCharacters: true };
  }

  public static lettersCharacters(c: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp = /^[a-zA-Z\-.äöüß 'ÀÂÄÇÉÈÊËÎÏÔÖÙÛÜŸàâäçéèêëîïôöùûüÿÆŒæœ]*$/;
    return regExp.test(c.value) ? null : { lettersCharacters: true };
  }

  public static phone(control: AbstractControl): { [key: string]: any } {
    const regex: RegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    return regex.test(control.value) || !control.value ? null : { phone: true };
  }

  public static email(c: UntypedFormControl): { [key: string]: any } {
    const regExp: RegExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !c.value || regExp.test(c.value) ? null : { email: true };
  }

  public static endBeforeStart(control: AbstractControl): { [key: string]: any } {
    return control.get('startDate').value <= control.get('endDate').value ? null : { endBeforeStart: true };
  }

  public static sameFund(fundNumber: string): ValidatorFn {
    const error = 'sameFund';
    const sameFund = (control: UntypedFormControl): { [key: string]: any } => {
      return !control.value || control.value !== fundNumber ? null : { [error]: true };
    };
    return getFunctionWithName(sameFund, error);
  }

  public static website(c: UntypedFormControl): { [key: string]: any } {
    if (!c.value) return null;
    const regExp: RegExp =
      /^(https:\/\/|http:\/\/)(www\.)?[a-zA-Z0-9.-]{2,256}\.[a-z]{2,15}([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    const regExpArray = regExp.exec(c.value);
    return regExpArray && regExpArray[0] === regExpArray.input ? null : { website: true };
  }

  public static noDecimals(control: AbstractControl): { [key: string]: any } {
    // dummy validation to show the error message
    return null;
  }
}
